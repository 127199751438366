<div class="sidebar-body">
	<img *ngIf="provider.logo_black_background" src="{{provider.logo_black_background}}" class="img-responsive sidebar-logo" width="200px" style="display: inline-block;" alt="{{provider.name}}">

	<button type="button" class="navbar-toggler" (click)="showMobileMenu = !showMobileMenu">
	   <span class="navbar-toggler-icon"></span>
	</button>
	<div class="sidebar-content" [ngClass]="{'sidebar-content-open': showMobileMenu}">

		<div class="media">
	  		<div class="media-left">
	    		<a uiSrefActive="active" uiSref="app.profile">
	      			<img *ngIf="!avatar" src="assets/images/avatar.png" width="50" alt="profle image" class="profile-img"/>
	      			
	      			<img *ngIf="avatar" src="{{avatar}}" width="50" alt="{{name}}" class="profile-img"/> 
	    		</a>
	  		</div>
	  		<div class="media-body">
	  			
	  			<!-- <span class="icon icon-pencil" ui-sref="app.provider.admin"></span> -->
	  			<a uiSref="app.provider.adminEdit" [uiParams]="{admin: adminId}"><small>Welcome back</small><br/> {{ name }}</a>
	<!-- 	  				<strong>{{staffLeader.firstname}} {{staffLeader.surname}}</strong>
	-->	  		
	  		</div>
		</div>

		<ul class="list-unstyled">
			<li>
				<a uiSrefActive="active" uiSref="app.dashboard" [uiParams]="{ id: provider.id }">
					<span class="icon icon-chart-pie"></span> 
					Dashboard
				</a>
			</li>
			<li>
				<a uiSrefActive="active" uiSref="app.provider.products" [uiParams]="{ id: provider.id }">
					<span class="icon icon-cube"></span> 
					Products
				</a>
			</li>
			<li>
				<a uiSrefActive="active" uiSref="app.bids" [uiParams]="{ id: provider.id }" >
					<span class="icon icon-hammer"></span> 
					Bids
				</a>
			</li>
			<li>
				<a uiSrefActive="active" uiSref="app.provider.provider" [uiParams]="{ id: provider.id }">

					<span class="icon icon-cog-1"></span> 
					Provider Profile
				</a>
			</li>
			<li>
				<a uiSrefActive="active" uiSref="app.provider.plan" [uiParams]="{ id: provider.id }">
					<span class="icon icon-pound"></span> 
					Plan
				</a>
			</li>
			<li *ngIf="provider.view_all_providers">
				<a href="{{ customerMarketplace }}">
					<span class="icon icon-search"></span> 
					Client Search
				</a>
			</li>
			<li *ngIf="provider.view_all_providers">
				<a uiSrefActive="active" uiSref="app.productTypes">
					<span class="icon icon-cubes"></span> 
					Product Types
				</a>
			</li>
			<li *ngIf="provider.edit_rewards">
				<a uiSrefActive="active" uiSref="app.rewards">
					<span class="icon icon-tags"></span> 
					Rewards
				</a>
			</li>
			<li *ngIf="provider.view_all_providers">
				<a uiSrefActive="active" uiSref="app.sellers">
					<span class="icon icon-basket"></span> 
					Providers
				</a>
			</li>
			<li>
				<a (click)="logout()">
					<span class="icon icon-power"></span> 
					Logout
				</a>
			</li>
		</ul>
	</div>
</div>