import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MessageComponent } from './message.component';
import { MessageService } from './message.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
  	MessageComponent
  ],
  exports:[
  	MessageComponent
  ]
})
export class MessageModule { 

	static forRoot(): ModuleWithProviders {
	    return {
	      ngModule: MessageModule,
        providers: [
          MessageService
        ]
	    }
	}

}
