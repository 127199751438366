import { Injector } from '@angular/core';
import { UIRouter, Category } from '@uirouter/core';
import { environment } from '../environments/environment'
import { CookieService } from 'ngx-cookie-service';
import { BackendService } from '@nakedcreativity/wp-backend'
//import { googleAnalyticsHook } from './util/ga';
//import { requiresAuthHook } from './global/auth.hook';

export function routerConfigFn(router: UIRouter, injector: Injector) {
  const transitionService = router.transitionService;


  let backendService: BackendService = injector.get(BackendService)
  let cookieService: CookieService = injector.get(CookieService)

  

  transitionService.onBefore({from: 'app.provider.adminEdit'}, (transition) =>{
  	console.log('transition from provider admin profile')

  	if(subscription){
  		console.log('reissue subscription exists already')

  	}else {
  		console.log('create new token subscription')
	  	backendService.reissue(cookieService.get('membrs'));
	    var subscription = backendService.token.subscribe(result => {
	      if(result && result != cookieService.get('membrs')){
	      	console.log('received new token', result)
	        cookieService.set('membrs', result, 1, '/', environment.cookieDomain, environment.cookieSecure)
	        subscription.unsubscribe()
	      }
	    }, error => {
	      	console.log(error)
	      	subscription.unsubscribe()
	    })
	}

  })


  transitionService.onFinish({}, (transition)=>{
  	window.scrollTo(0,0)
  })

}
