import { Injectable } from '@angular/core';
import { BackendService } from "@nakedcreativity/wp-backend"
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { UIRouter, Transition } from "@uirouter/angular";
import { CookieService } from 'ngx-cookie-service';
import { environment } from  '../../environments/environment'

@Injectable()

export class ProviderDataService {

	private _provider: BehaviorSubject<any> = new BehaviorSubject({})
  	private _providerId: any
  	private dateQueryString:string

  	constructor(private backend: BackendService, private router: UIRouter, private cookieService: CookieService) {

      

      this.backend.decodedToken.subscribe(decodedToken => {

        this.backend.get('provider/'+decodedToken.marketplaceId).subscribe(data=>{
          this._provider.next(data)
        })

      })

      // if I don't find a cookie, send to login page
      // to-do if token has expired, send to login page


  	}





  	retrieveProviderConfig(client=null){

      if(client != null){
        this._providerId = client;
      }
      else
      {
      	//this.providerId = 2;
        this._providerId = this.backend.decodedToken.value.marketplaceId
         //this.providerId = this.backend.decode().marketplaceId;
      }

       //console.log(this.clientId,'Client in retrieveClientConfig()');

  	  
  		
  	}


    generateReport(product,startDate=null,endDate=null){

      //console.log(product,'Product in generateReport');
      //console.log(startDate,'Start date in generateReport');
      //console.log(endDate,'End date in generateReport');


      if(startDate !== null && endDate !== null){
        this.dateQueryString = '?start='+startDate+'&end='+endDate+'&';
      }else{
        this.dateQueryString = '?';
      }

      var token = this.cookieService.get('membrs');

      window.open( environment.apiEndpoint +'/provider/'+this.providerId+'/search-report/'+product+this.dateQueryString+'authorization='+token , '_blank');

    }


    downloadInvoice(file,invoiceId){

      var token = this.cookieService.get('membrs');

      window.open( environment.apiEndpoint +'provider/'+this.providerId+'/invoice/'+invoiceId+'/download/'+file+'?authorization='+token , '_blank');

    }




    get providerId(){
      return this._provider.value.id
    }

    get provider(){
      return this._provider
    }

    set provider(data){
      this._provider.next(data)
    }

    get providerConfig(){
      return this._provider
    }

    set providerConfig(data){
      this._provider.next(data)
    }

}
