import { Component, OnInit } from '@angular/core';
import { ProviderDataService } from './shared/provider-data.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent{
  title = 'app';

  constructor(private providerDataService: ProviderDataService){

  	

  }
 
}
