<div class="reissue-table">
	<div class="reissue-cell">
		<div class="reissue-error" *ngIf="error">
			<span class="icon icon-warning"></span>
			<p>{{error}}</p>
		</div>

		<div class="reissue-success" *ngIf="!error">
			<span class="icon icon-refresh spin"></span>
			<h1>Loading your Marketplace...</h1>
		</div>
	</div>
</div>