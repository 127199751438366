import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { UIRouter, Transition } from "@uirouter/angular";
import {Observable} from 'rxjs/Observable';

@Injectable()
export class MessageService {

 	
 	private subject: BehaviorSubject<any>

 	constructor() {
 		this.subject = new BehaviorSubject({})
 	}

    sendMessage(message: string, type: string) {
        this.subject.next({ text: message, type: type });
    }

    clearMessage() {
        this.subject.next(null);
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}



