import { Component, OnInit } from '@angular/core';
// import { ApiService } from '../api-service/api.service';
import { UIRouter, Transition } from "@uirouter/angular";
import { BackendService } from '@nakedcreativity/wp-backend';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment'


@Component({
  selector: 'app-reissue',
  templateUrl: './reissue.component.html',
  styleUrls: ['./reissue.component.less']
})
export class ReissueComponent implements OnInit{

  public error:string
  private subscription: any
  private subscription2: any

  constructor(private backend: BackendService, private router: UIRouter, private transition: Transition, private cookieService: CookieService) {
      
  }

  ngOnInit(){

    console.log('reissue on ng init')
    this.backend.reissue(this.transition.params().membrs);
    this.subscription = this.backend.token.subscribe(result => {
      if(result){
        this.cookieService.set('membrs', result, 1, '/', environment.cookieDomain, environment.cookieSecure)
      }
    }, error => {
      console.log(error)
    })

    this.subscription2 = this.backend.decodedToken.subscribe(result => {
      if(result){
        this.router.stateService.go('app.dashboard', {id: result.marketplaceId});  
      }
      

    })
  }

  ngOnDestroy(){
    this.subscription.unsubscribe()
    this.subscription2.unsubscribe()
  }

}
