import { Injectable } from '@angular/core';
import { BackendService } from "@nakedcreativity/wp-backend"
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { UIRouter, Transition } from "@uirouter/angular";

@Injectable()
export class CustomerService {

	private _customerProfile: BehaviorSubject<any>
	private _customerId: any

  	constructor(private backend: BackendService,private router: UIRouter) {

  		this._customerProfile = new BehaviorSubject({})

  	}

  	get profile(){
  		return this._customerProfile
  	}

  	set profile(data){
  		this._customerProfile.next(data)
  	}


  	retrieveCustomer(id){

  		return new Promise((resolve, reject) => {
  			this.backend.request('GET', 'customer/:customerId', {customerId: id})
	  		.then((response)=>{
	  			this._customerProfile.next(response)
	  			resolve()
	  		})
	  		.catch(error=>{
	  			//console.log(error)
	  			reject(error.message)
	  		})

  		})
  		
  	}

}
